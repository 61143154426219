<template>
  <section class="section__container">
    <div class="section__container_background">
      <img
        max-width="100%"
        height="100%"
        src="../../assets/conteudos_violacoes_direito_background_05.png"
        alt=""
      />

      <GoBack />
    </div>
    <div class="container position--relative">
      <section class="container">
        <v-row class="d-flex justify-center flex-column align-center">
          <div class="d-flex align-self-start pt-2 pb-2">
            <Breadcrumb
              style="color: white"
              class="conteudos_tematicos_section_bc-color"
            >
              <template v-slot:breadcrumbLinks>
                <router-link
                  tag="p"
                  :to="{ name: link.href }"
                  v-for="(link, idx) in breadcrumbItems"
                  :key="idx"
                >
                  {{ link.text }}
                </router-link>
              </template>
            </Breadcrumb>
          </div>
          <v-col class="d-flex justify-center align-center">
            <img
              width="50px"
              src="../../assets/conteudos_violacoes_direito_title_engine.png"
              alt=""
            />
            <h3 class="ml-3">
              Dano moral atrelado à propriedade do terreno
            </h3>
          </v-col>
        </v-row>
      </section>
      <section class="container">
        <v-row class="d-flex justify-center align-center">
          <v-col cols="12" md="8">
            <Card borderColor="#426E51" backgroundColor="#F9F7ED">
              <template v-slot:conteudo>
                <p>
                  Em decorrência do rompimento da barragem de Fundão em Mariana,
                  muitas famílias sofreram danos diversos, pelos quais lutam por
                  reparação há mais de seis anos. Muitas dessas perdas
                  ultrapassam o aspecto patrimonial ou material, o que não pode
                  ser ignorado, uma vez que se busque a
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'conteudos_tematicos_luta_reparacao' }"
                    >reparação integral</router-link
                  >
                  a que todos os núcleos familiares atingidos têm direito.
                </p>
                <p>
                  Nesse sentido, muitas pessoas atingidas sofreram, e ainda
                  sofrem, com os danos relacionados aos seus projetos de vida,
                  com a perda das
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'conteudos_tematicos_rel_comunitarias' }"
                    >relações comunitárias</router-link
                  >, com as
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{
                      name: 'conteudos_tematicos_crianca_adolescente_traumas',
                    }"
                    >lesões psicológicas</router-link
                  >, as
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'conteudos_tematicos_ser_atingido_tempos' }"
                    >mudanças no uso do tempo</router-link
                  >, a perda do vínculo com a natureza, bem como com toda a
                  abrupta
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{
                      name: 'conteudos_tematicos_crianca_adolescente_ser',
                    }"
                    >mudança nos modos de vida</router-link
                  >. Além disso, os danos se renovam e se perpetuam com o passar
                  do tempo, haja vista o longo e desgastante processo pelo qual
                  as pessoas atingidas são submetidas na busca pela reparação
                  integral — uma reparação justa, que leve em consideração toda
                  a complexidade dos processos vivenciados pelas pessoas
                  atingidas de Mariana, desde o rompimento até os dias atuais.
                </p>
                <p>
                  Apesar do princípio da autodeclaração ter sido pactuado como
                  uma das diretrizes a ser observada no âmbito das negociações
                  entre as pessoas atingidas e a Fundação Renova durante a Fase
                  de Negociação Extrajudicial (FNE)<sup>1</sup>, observa-se que,
                  não raras vezes, tal princípio é completamente
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{
                      name: 'conteudos_tematicos_desconsideracao_cadastro',
                    }"
                    >desconsiderado</router-link
                  >. O procedimento da FNE é conduzido de forma unilateral pela
                  Fundação Renova que, resguardando os interesses das empresas
                  mineradoras que a mantém, define quem é ou não elegível, isto
                  é, considerado para o recebimento de indenização de acordo com
                  cada categoria de dano por ela construída.
                </p>
                <p>
                  É importante explicar que os danos morais são uma espécie de
                  dano extrapatrimonial, ou seja, danos que vão além do
                  patrimonial e que impactam nos direitos da personalidade<sup
                    >2</sup
                  >
                  do indivíduo. Exatamente pela amplitude desses danos, torna-se
                  possível, desde que presente o nexo de causalidade — quer
                  dizer: no caso das pessoas atingidas, a relação entre o
                  rompimento da barragem e o resultado danoso —, reconhecer a
                  responsabilidade de indenizar em diversas situações nas quais
                  a vítima sofre uma lesão para além do aspecto patrimonial.
                </p>
              </template>
            </Card>
          </v-col>
        </v-row>
      </section>

      <section class="container">
        <v-row class="d-flex justify-center align-center mt-5">
          <v-col cols="12" md="8">
            <Card borderColor="#426E51" backgroundColor="#F9F7ED">
              <template v-slot:conteudo>
                <p>
                  O
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_cadastro' }"
                    >Cadastro</router-link
                  >
                  aplicado pela Assessoria Técnica Independente da Cáritas MG
                  permite que as particularidades dos danos declarados pelas
                  pessoas atingidas sejam consideradas e respeitadas no momento
                  em que relatam as perdas sofridas em virtude do rompimento da
                  barragem. No entanto, no processo de negociação extrajudicial,
                  a Fundação Renova se ampara em sua própria matriz de danos —
                  construída unilateralmente e baseada em critérios que não são
                  transparentes —, para definir o que considera ou não como dano
                  moral. Em muitos casos, o núcleo familiar sequer tem a
                  possibilidade de debater com a Fundação Renova sobre os danos
                  que sofreu, recebendo, tão somente, uma carta na qual se traz
                  a informação que as pessoas atingidas que compõem aquele
                  núcleo
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{
                      name: 'conteudos_tematicos_ser_atingido_nao_reconhecidos',
                    }"
                    >não foram reconhecidas</router-link
                  >
                  para o recebimento de indenização pela FNE.
                </p>
                <p>
                  A partir das categorias de danos morais presentes na matriz
                  disponibilizada no site da Fundação Renova<sup>3</sup>,
                  nota-se que, na contramão do entendimento consolidado pelos
                  autores e Tribunais Brasileiros, os danos imateriais estão
                  vinculados a uma perspectiva essencialmente patrimonialista,
                  sem qualquer respaldo jurídico. O máximo de indenização
                  delimitado pela Fundação Renova pelos danos morais causados é
                  devido apenas aos proprietários(as), filhos e filhas de
                  proprietário(a) e residentes nas comunidades atingidas no
                  momento do rompimento, de modo que o imóvel é sempre colocado
                  como referencial para a indenização e não as próprias
                  violações sentidas e declaradas pelas pessoas atingidas.
                </p>
                <p>
                  É importante pontuar que, mesmo não tendo o registro de
                  propriedade de bem imóvel na localidade atingida, ficam
                  evidentes, por meio da análise atenta do
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_sistematizacao_dossie' }"
                    >dossiê de cadastro</router-link
                  >
                  e da escuta às pessoas atingidas, todas as perdas imateriais
                  vivenciadas pelos núcleo familiares em razão do rompimento da
                  barragem. Tais perdas se deram sobretudo quanto às relações
                  construídas com a comunidade e seu povo durante toda uma vida
                  e, também, quanto à dimensão da lesão acarretada pela perda
                  dos territórios atingidos, inclusive para as gerações futuras,
                  que foram impedidas de experimentar o contato com um lugar tão
                  importante para a produção e reprodução da vida de sua
                  família.
                </p>
                <p>
                  De acordo com os critérios construídos pela Fundação Renova, o
                  dano imaterial declarado pelo núcleo familiar que não esteja
                  ligado à propriedade caracteriza-se como indireto e, portanto,
                  não pode ser indenizado em seu programa, a FNE. No entanto, é
                  extremamente importante salientar, mais uma vez, que o que
                  deve ser levado em consideração na análise dos danos morais é
                  a própria essência do dano. Este se revela como lesão a
                  direitos da personalidade, sem trazer em seu bojo,
                  necessariamente, qualquer relação direta com direito a
                  propriedade, conforme pressupõe a Fundação Renova pelos seus
                  critérios objetivos de elegibilidade.
                </p>
                <p>
                  Convida-se à leitura do
                  <a
                    target="_blank"
                    style="text-decoration: none"
                    href="https://www.google.com/url?q=http://mg.caritas.org.br/storage/arquivo-de-biblioteca/December2021/ZSzuQyeDEmzOnKWzaXL7.pdf&sa=D&source=docs&ust=1653146093495057&usg=AOvVaw0U-VdAtCGHL5Bp8DqQcJmC"
                    class="pink--text"
                    >Parecer Técnico Jurídico</a
                  >
                  produzido pela equipe de Assessoria Jurídica da Cáritas MG, em
                  que foram relatadas, com mais detalhes, as práticas e
                  violações da Fundação Renova, no âmbito da FNE, perpetradas a
                  fim de negar a
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'conteudos_tematicos_luta_reparacao' }"
                    >reparação integral</router-link
                  >
                  dos danos decorrentes do rompimento da barragem de Fundão4.
                </p>

                <p style="text-align: center">
                  <strong
                    >Autoria: Equipe de Assessoria Jurídica da ATI de
                    Mariana</strong
                  >
                </p>
              </template>
            </Card>
          </v-col>
        </v-row>
      </section>

      <section class="container pb-10 mt-4 section__mb">
        <v-row class="d-flex">
          <v-col cols="12">
            <div class="inside__card">
              <p>
                <sup>1</sup> Em audiência realizada em 05 de outubro de 2017,
                pactuou-se que todas as pessoas que se autodeclarassem como
                atingidas poderiam preencher o cadastro. Além disso, na mesma
                ata de audiência, há previsão expressa de que, durante o
                cadastro, a pessoa atingida teria plena liberdade para declarar
                todos os danos sofridos. Por fim, no TTAC firmado em 02 de
                outubro de 2018 foi pactuado em favor das pessoas atingidas,
                diante de sua manifesta vulnerabilidade, a garantia da inversão
                do ônus da prova. Nesse sentido, observa-se que o princípio da
                autodeclaração consiste na inexigibilidade de que a pessoa
                atingida produza provas acerca dos danos sofridos que foram
                declarados na fase de cadastramento junto à Assessoria Técnica
                Independente da Cáritas MG.
              </p>
              <p>
                <sup>2</sup> Daniel Eduardo Carnacchioni (2011, p. 217)
                conceitua os direitos da personalidade como “[...] direitos
                subjetivos, essenciais, inatos, permanentes (vitalícios) e
                fundamentais para resguardar a dignidade da pessoa humana. Tais
                direitos são subjetivos porque inerentes à condição humana,
                tendo natureza existencial. Não há pessoa humana sem
                personalidade. [...] Inatos porque independem de qualquer
                previsão legal para serem reconhecidos, ou seja, são
                contemporâneos ao início da existência da pessoa humana.
                Permanentes porque perduram por toda a vida da pessoa e
                fundamentais para o ser humano ter uma existência digna”.
              </p>
              <p>
                <sup>3</sup> De acordo com as categorias de danos morais
                indenizáveis presentes na Matriz de Danos da Fundação Renova,
                são indenizáveis: a) danos morais por deslocamento físico
                temporário; b) danos morais por falecimento ou desaparecimento
                de familiares por conta do rompimento da barragem; c) danos
                morais por impossibilidade de exercício da atividade laborativa;
                d) danos morais por interrupção da atividade laboral de pesca;
                e) danos morais por invasão da propriedade pela lama; f) danos
                morais por deslocamento físico permanente; g) danos morais pela
                interrupção na atividade de pesca de subsistência; h) danos
                morais interrupção na atividade de faiscadores; i) danos morais
                por deslocamento físico de estabelecimentos comerciais fixos.
              </p>
              <p>
                <sup>4</sup>
                <a
                  class="pink--text"
                  style="text-decoration: none"
                  href="http://mg.caritas.org.br/storage/arquivo-de-biblioteca/December2021/ZSzuQyeDEmzOnKWzaXL7.pdf"
                  >http://mg.caritas.org.br/storage/arquivo-de-biblioteca/December2021/ZSzuQyeDEmzOnKWzaXL7.pdf</a
                >
              </p>
            </div>
          </v-col>
        </v-row>
      </section>

      <section class="container pb-10 mt-4 section__mb">
        <v-row class="d-flex">
          <v-col cols="12">
            <div class="inside__card">
              <p>
                <strong>Referências Bibliográficas</strong>
              </p>
              <p>
                CARNACCHIONI, Daniel Eduardo.
                <strong>Curso de Direito Civil</strong>: parte geral: institutos
                fundamentais. 2. ed. Rio de Janeiro: Lumen Juris, 2011.
              </p>
              <p>
                FUNDAÇÃO RENOVA. <strong>Matriz de Danos</strong>. [Mariana],
                2019. Disponível em:
                <a
                  style="text-decoration: none;"
                  href="chrome-extension://efaidnbmnnnibpcajpcglclefindmkaj/viewer.html?pdfurl=https%3A%2F%2Fwww.fundacaorenova.org%2Fwp-content%2Fuploads%2F2018%2F09%2Fmatrizdedanos20190703.pdf&clen=1196021&chunk=true"
                  class="pink--text"
                  >chrome-extension://efaidnbmnnnibpcajpcglclefindmkaj/viewer.html?pdfurl=https%3A%2F%2Fwww.fundacaorenova.org%2Fwp-content%2Fuploads%2F2018%2F09%2Fmatrizdedanos20190703.pdf&clen=1196021&chunk=true</a
                >. Acesso em: 04 mar. 2022.
              </p>
              <p>
                TARTUCE, Flávio. <strong>Manual de Direito Civil</strong> –
                volume único. 8. ed. rev., atual. e ampl. Rio de Janeiro:
                Forense; São Paulo: Método, 2018.
              </p>
            </div>
          </v-col>
        </v-row>
      </section>

      <section class="container section__mb">
        <v-row class="d-flex justify-center align-center pa-4 section__mb">
          <v-col cols="12" md="7" class="d-flex justify-center align-center">
            <v-btn
              :to="{ name: 'conteudos_tematicos_violacoes_impossibilidade' }"
              color="#EDBD43"
            >
              <strong
                >Conhecer "Impossibilidade de alteração dos termos"</strong
              ></v-btn
            >
          </v-col>
        </v-row>
      </section>
    </div>
  </section>
</template>

<script>
import Card from "@/components/Card.vue";
import Breadcrumb from "@/layout/Breadcrumb.vue";
import GoBack from "@/components/GoBack.vue";

export default {
  components: {
    Card,
    Breadcrumb,
    GoBack,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos / ",
          href: "conteudos_tematicos",
        },

        {
          text: "Violações de Direito /",
          href: "conteudos_tematicos_violacoes_direitos",
        },

        {
          text: "Dano moral atrelado à propriedade do terreno",
          href: "conteudos_tematicos_violacoes_dano_moral",
        },
      ],
    };
  },
  created() {},
};
</script>

<style scoped>
.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

.v-btn {
  width: 80%;
  word-wrap: normal;
  white-space: nowrap;
  text-align: center;
}
.section__container {
  position: relative;
}

.section__container_background {
  position: fixed;
  top: 0;
}

h3 {
  color: white;
}

@media (max-width: 600px) {
  .section__container_background {
    position: relative;
    display: flex;
    justify-content: center;
  }
  h3 {
    color: black;
  }
}

@media (min-width: 1400px) {
  .section__container_background {
    display: flex;
    justify-content: center;
  }
}

.position--relative {
  position: relative;
}

.card__footer_img {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.card__footer_img img {
  width: 40%;
}

.card__footer_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: white;
}

.section__mb {
  padding-bottom: 3em;
}
</style>
